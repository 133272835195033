import React from "react";

const TradeCard = (props) => {
  return props.data.map((content, i) => {
    return (
      <div className="col-md-6 col-lg-4 col-xl-4 project-single-item uiux">
        <div className="single-portfolio">
          <div className="portfolio-data">
            <a href="#">
              <img src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ8NDQ0NFREWFhURFRUYHSggGBolGxUVITEhJSkrOi46Fx8zODMtNygtLisBCgoKDQ0NDw0NDysZFRkrLTcrLTcrKystLSstKysrKy0rKysrKysrLSsrKy0rKysrKysrKysrKysrKysrKysrK//AABEIAQMAwgMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQcC/8QAFhABAQEAAAAAAAAAAAAAAAAAAAER/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDDgFABFAAABAAAAAAAVABQEFBQAQAFAARRFFRRBAUEVFARQEVFEBFFEUAAAAUAEQAFAFAAAAAAEUEBFFAAABAAUAAAAAAAAAEABQAAEUAEQUEUFBAEAUBQAQAAAFQAFAAAABFAAAEUEURAVFERUAUEUVFEUAAAAAUAAAAAAARAAEBQVAURABQUERQAAFABAAUAUAAAAEBEAAAFABAAAAAVFAAAAFAFAAAAAAARQQFREAAAAAAAAFQFEVVAEQAVQAAAAAEUAEFQQVFQBUEAAAABQABQBQAAAAAAAAABFAEARBUUBBQRQABFFAFAAAAAAAAAAAAAEQAVQEQAUQAUAAABQAAAAAAAAAAAAAAAEUAEUEABQBAAAAUAAAEABQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAFAAAAAAAAAAAAAAAVAAUEAAAAAAAAAAAAAAAAAQAVRBQBHVQEUAQUBFAEFAQFBBQEFQAUBBQEFAAUEwVRUqKCIAAACKAAAAAFAQAFEUAAAKQAFUFFBR/9k=" alt="" />
            </a>
          </div>
          <div className="portfolio-inner" style={{height: "400px", width:"330px", margin:"0px"}}>
            <span>{content.span}</span>
            <h4 style={{position: "relative", top:"50%"}}>{content.heading}</h4>
          </div>
        </div>
      </div>
    );
  });
};

export default TradeCard;
