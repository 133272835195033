import { BreadCrumbMenu } from "../components/BreadCrumb";
import TradeCard from "../components/TradeCard";
export const Trades = () => {
  const trade_data = [
    {
      image: "img/portfolio/portfolio-1.jpg",
      // span: "Security Personnel",
      heading: "Security Personnel",
    },
    {
      image: "img/portfolio/portfolio-2.jpg",
      // span: "UI KiT",
      heading: "Military Trainers",
    },
    {
      image: "img/portfolio/portfolio-3.jpg",
      // span: "UI KiT",
      heading: "Professional Commandosrce",
    },
    {
      image: "img/portfolio/portfolio-4.jpg",
      // span: "UI KiT",
      heading: "Doctors (Specialists/ Consultants)",
    },
    {
      image: "img/portfolio/portfolio-5.jpg",
      // span: "UI KiT",
      heading: "Nurses",
    },
    {
      image: "img/portfolio/portfolio-6.jpg",
      // span: "UI KiT",
      heading: "Paramedics",
    },
    {
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Engineers",
    },
    {
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Architects",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Surveyors",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Hospitality Staff",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Housekeeping Staff",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Chefs/ Cooks",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Heavy Machinery Operators",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Drivers",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Utility Operators",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Telecom Technicians",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Medical Technicians",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "General Technicians",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Construction Staff",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Electricians (Building/ Mechanical)",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Carpenters",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Plumbers",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "AC/HVAC Technicians",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Machinists",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Janitorial",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Support Staff",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Secretarial Staff",
    },{
      image: "img/portfolio/portfolio-7.jpg",
      // span: "UI KiT",
      heading: "Certified IT Resources",
    },
  ];
  return (
    <>
      <BreadCrumbMenu name="Specialized Areas" />
      <section className="project-area sec-mar">
        <div className="container border-wrapper" id="container">
          <div className="row g-4 project-items-wrapper">
            <h1>Specialized Areas</h1>
            <TradeCard data={trade_data} />
          </div>
        </div>
      </section>
      <div className="divider"></div>
    </>
  );
};
