import React from "react";
import ClientsCard from "./ClientsCard";

const AboutUs = (props) => {
  return (
    <section className="about-area sec-mar">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-xl-6">
            <div className="about-left">
              <div className="title black">
                

                <h2 className="mb-15">About us.</h2>
              </div>
              <p>
               
Capital Employment
 Agency, was established in 1978 and has been providing highly competent, skilled, semi-skilled and unskilled manpower globally. Over 4 decades of rich employment experience gives Capital Employment Agency an edge that not many can claim or compete with. 


The primary objective of the Capital Employment Agency is to satisfy our clients, the prospective employees and the companies they are hired in. International companies are provided with manpower with the required qualification and experience. 


Our recruitment process meets the highest standards of excellence for international companies and our primary goal is the wellbeing of the employees we select, train and hire. 
 

              </p>
              
            </div>
          </div>
          <div className="col-lg-6 col-xl-6">
            <div className="about-right">
              <div className="group-images">
                <img src="/img/manpower_pics/654321.jpg" alt="" />

              </div>
            </div>
          </div>
        </div>
        {props.show && <ClientsCard />}
      </div>
    </section>
  );
};

export default AboutUs;
