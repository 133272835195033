import { Carousel_One } from "./homeComponents/Carousel_One";
import { Procedure } from "../../components/Procedure";
import { CommunityCards } from "../../components/CommunityCards";
import { CommunityCardstwo } from "../../components/CommunityCardstwo";
import AboutUs from "../../components/AboutUsComponents/AboutUs";
export const HomeOne = () => {
  const data_carousel_1 = [
    {
      image_url: "img/manpower_pics/123456.jpg",
      span: "Teamwork.",
      heading:"Building teams, not just filling positions"
    },
    // {
    //   image_url: "img/manpower_pics/19340.jpg",
    //   span: "Opportunity.",
    //   heading:"Uniting Talent with Opportunity"
    // },
    // { image_url: "img/manpower_pics/21207.jpg", span: "Markets.",
    // span: "Trust.",
    // heading:"Trained and well-groomed manpower in every possible trade" },
  ];

  

  const data_card_1 = [
    {
      heading: "Credibility",
      paragraph:
        "Licensed Organization",
    },
    {
      heading: "Quality",
      paragraph:
        "Human Resource Development",
    },
    {
      heading: "Skill Refinement",
      paragraph:
        "Grooming and finishing",
    },
    {
      heading: "Experienced Organization",
      paragraph:
        "Since 1978",
    },
    {
      heading: "Economical",
      paragraph:
        "The best for less",
    },
    {
      heading: "Huge database",
      paragraph:
        "200K Plus people Registered",
    },
  ];

  const community_data = [
    {
      image: "img/partner-icons/p1.png",
      text:"Omar Kassem Alesayi Holding Group"
    },
    {
      image: "img/partner-icons/p2.png",
      text:"Saudi Aramco"
    },
    {
      image: "img/partner-icons/p9.png",
      text:"Skansa"
    },
    {
      image: "img/partner-icons/p7.png",
      text:"Ministry of Municipal and Rural Affairs"
    },
    {
      image: "img/partner-icons/p3.png",
      text:"Stratgurus Group of Company"
    },
    {
      image: "img/partner-icons/p4.png",
      text:"Anker Pixel General Trading LLC"
    },
    {
      image: "img/partner-icons/p8.png",
      text:"Ryan Baker"
    },
    {
      image: "img/partner-icons/p6.png",
      text:"EMEC"
    },
    {
      image: "img/partner-icons/p5.png",
      text:"DEKO"
    },
    {
      image: "img/partner-icons/p10.png",
      text:"Saad Al Riyadh Contracting Company"
    },
  ];

  const partner_data = [
    {
      image: "img/partner-icons/p12.png",
      text:"Hazza Institute of Technology"
    },
    {
      image: "img/partner-icons/p13.png",
      text:"MSM"
    },
    {
      image: "img/partner-icons/p14.png",
      text:"National Vocational and technology Training Commission"
    },
    {
      image: "img/partner-icons/p15.png",
      text:"Pak Datacom"
    },
    {
      image: "img/partner-icons/p11.png",
      text:"National Testing Service"
    },
    
  ];

  
  return (
    <>
      <section className="hero-area">
        <div className="swiper hero-slider">
          <div>
            <Carousel_One data={data_carousel_1} />
          </div>
        </div>
      </section>

      <section className="service-area sec-pad" style={{
        marginTop: "20px"
      }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4 col-xl-4">
              <div className="title">
                <span>Why Us</span>
                <h2>Uniting Talent with Opportunity</h2>
              </div>
            </div>
            <div className="col-md-12 col-lg-8 col-xl-8">
              <div className="row g-4">
                <Procedure data={data_card_1} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <AboutUs show={true}/>

      <section className="our-partner">
        <div className="container-fluid g-0 overflow-hidden">
          <div className="row align-items-center g-0">
            <div className="col-12 col-xl-12">
              <div className="our-clients">
                <div className="row align-items-center">
                  <div className="col-12 col-xl-6">
                    <div className="title">

                      <h2>Our Clients</h2>
                    </div>
                  </div>
                  <CommunityCards data={community_data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-partner">
        <div className="container-fluid g-0 overflow-hidden">
          <div className="row align-items-center g-0">
            <div className="col-12 col-xl-12">
              <div className="our-clients">
                <div className="row align-items-center">
                  <div className="col-12 col-xl-6">
                    <div className="title">
                    
                      <h2>Our partners</h2>
                    </div>
                  </div>
                  <CommunityCardstwo data={partner_data} />
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>

    </>
  );
};
