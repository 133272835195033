import React from "react";
import {ShowProgress} from "./ShowProgress";

const CoreValues = () => {
  return (
    <section className="why-choose-us sec-mar">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-10">
            <div className="title">
              
              <h2 className="mb-15">
              License
              </h2>
            </div>
            <div className="video-demo">
              <img src="../img/license.png" alt="" />
             
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default CoreValues;
