
export const CommunityCards = (props) => {
    {
      return props.data.map((content, index) => {
        return (
          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3" key={index} >
            <div className="single-client">
              <img src={content.image} alt="" style={{margin:"50px"}}/>
              <div className="client-hover">
                {/* <span>{content.text}</span> */}
              </div>
            </div>
          </div>
        );
      });
    }
  };