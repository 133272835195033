export const Procedure = (props) => {
    {
      return props.data.map((content, index) => {
        return (
          <div key={index}className="col-sm-6 col-md-6 col-lg-6 col-xl-6" >
            <div className="single-service" >
            
              <div className="service-content">
                <h4>{content.heading}</h4>
                <p>{content?.paragraph}</p>
                <p>{content?.para2}</p>
                <p>{content?.para3}</p>
                <p>{content?.para4}</p>


              </div>
            </div>
          </div>
        );
      });
    }
  };