import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Carousel_One = (props) => {
    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      // autoplay: true,
      // autoplaySpeed: 3000,
    };
  
    return (
      <Slider {...sliderSettings}>
        {props.data.map((slide, i) => {
          return (
            <div className="swiper-slide" key={i}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="hero-content-wrapper">
                      <div className="hero-content-wrap" >
                        <div className="hero-content-img" >
                          <img src={slide.image_url} alt=""  />
                        </div>
                        <div className="hero-content">
                          {/* <h2 >Excellence</h2> */}
                          <h1>
                            Capital Manpower  <span>{slide.span}</span>
                            {/*  */}
                          </h1>
                          <h3 style={{color:"white"}}>
                            {slide?.heading}
                          </h3>
                          {/* <div className="slider-num">
                            <span>0{i + 1}</span>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    );
  };