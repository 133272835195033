import { BreadCrumbMenu } from "../components/BreadCrumb";
import { Proceduretwo } from "../components/Proceduretwo";

export const ProcedureDetails = () => {
  const data_card_1 = [
 
    {
      heading0: "Processing",
      para01:
        "The recruitment procedure at Capital Employment Agency is efficient and quick. On receipt of the original recruitment documents from the client, interviews are organized within 7 to 10 days.  The following original documents are required from the client:",
      para02:"Demand Letter",
      para03:"Power of Attorney",
      para04:"Employment Agreement",
      para05:"Contract Agreement between the Company and GOC",
      para06:"Guarantee Letter ",

      heading1: "Interview Process",
      para11:
        "Day 01: Advertising in authorized daily newspapers.",
      para12:" Day 05: Pre-screening of all suitable candidates",
      para13:" Day 08: Final interview of candidates by the client and trade tests where applicable.",
      heading2: "Medical Check-up",
      para2:
        "All the selected candidates are sent for medical fitness check-ups in the Government-authorized Medical Centers.",
      heading3: "Visa process",
      para3:"Only those candidates who are medically fit and available for travel are processed for Visa",
      heading4: "Orientation",
      para4:"The objective of orientation is to inform the candidates about the employing country, climatic conditions, jobs, culture, environment, laws & regulations before their departure. This could help them to adjust to their new environment quickly and perform well in their job."
    },
    
  ];
  return (
    <>
      <BreadCrumbMenu name="Recruitment Procedure" />
      <section className="service-area sec-pad">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4 col-xl-4">
              <div className="title">
                
                <h2>Building teams, not just filling positions</h2>
              </div>
            </div>
            <div className="col-md-12 col-lg-8 col-xl-8">
              <div className="row g-4">
                <Proceduretwo data={data_card_1} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
