import React from 'react'

export const Proceduretwo = (props) => {

    return props.data.map((content, index) => {
      return (
        <div key={index}className="col-sm-6 col-md-6 col-lg-6 col-xl-12" >
          <div className="single-service" >
            {/* <span className="count">0{index + 1}</span> */}
            {/* <div className="service-icon">
              <i>
                <img src="img/icons/service-icon-1.png" alt="" />
              </i>
            </div> */}
            <div className="service-content">
              <h4>{content.heading0}</h4>
              
              <p>{content?.para01}</p>
              <ul style={{marginBottom:"20px"}}>
              <li style={{color:"white",listStyle:"square", marginLeft:"20px"}}>{content?.para02}</li>
              <li style={{color:"white",listStyle:"square", marginLeft:"20px"}}>{content?.para03}</li>
              <li style={{color:"white",listStyle:"square", marginLeft:"20px"}}>{content?.para04}</li>
              <li style={{color:"white",listStyle:"square", marginLeft:"20px"}}>{content?.para05}</li>
              <li style={{color:"white",listStyle:"square", marginLeft:"20px"}}>{content?.para06}</li>
              </ul>
              <h4>{content.heading1}</h4>
              <p>{content?.para11}</p>
              <p>{content?.para12}</p>
              <p>{content?.para13}</p>
              <h4>{content.heading2}</h4>
              <p>{content?.para2}</p>
              <h4>{content.heading3}</h4>
              <p>{content?.para3}</p>
              <h4>{content.heading4}</h4>
              <p>{content?.para4}</p>


            </div>
          </div>
        </div>
      );
    });
}
