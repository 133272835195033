import { BreadCrumbMenu } from "../components/BreadCrumb";
import ServicesCards from "../components/ServicesCards";

export const Services = () => {
  const servicesCardData = [
    {
      // name: "Quality Manpower",
      image: "img/post/post-2.jpg",
      detail: "Ensuring Provision of Quality Manpower.",
    },
    {
      // name: "Ex-servicemen and Civilians",
      image: "img/post/post-2.jpg",
      detail: "Employment of Ex-servicemen and Civilians.",
    },
    {
      // name: "Web Design",
      image: "img/post/post-2.jpg",
      detail: "Job opportunities for Overseas and Inland",
    },
    {
      // name: "Web Design",
      image: "img/post/post-2.jpg",
      detail: "Emigration Services. ",
    },
    {
      // name: "Web Design",
      image: "img/post/post-2.jpg",
      detail: "Training and Refining Skills of manpower.",
    },
    {
      // name: "Web Design",
      image: "img/post/post-2.jpg",
      detail: "Character Building – Work Ethics      .",
    },
    {
      // name: "Web Design",
      image: "img/post/post-2.jpg",
      detail: "Imparting Social Graces – Etiquettes      .",
    },
    {
      // name: "Web Design",
      image: "img/post/post-2.jpg",
      detail: "One Window Comprehensive Workforce Solutions.",
    },
  ];
  return (
    <>
      <BreadCrumbMenu name="Services" />
      <section className="blog-news sec-mar">
        <div className="blog-wrapper" >
          <div className="row">
            <div className="col-md-8 col-lg-8 col-xl-8" id="services">
              <div className="row g-4" style={{
                textAlign: "center"
              }}>
              <h1>Services</h1>
                <ServicesCards data={servicesCardData} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="divider"></div>
    </>
  );
};
